export const AVAILABLE_CLASSES = {
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  10: '10',
};

export const AVAILABLE_SUBJECTS = {
  SCIENCE: 'Science',
  MATHS: 'Maths',
  ENGLISH: 'English',
};

export const SUBJECT_CLASS_PLACEHOLDER =
  'SUBJECT-worksheets-for-class-CLASSNAME';
