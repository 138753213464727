import React from 'react';

import { Link } from 'react-router-dom';
import { getColors } from '../../utilities/getColor';

import './styles.scss';

const UspCard = (props) => {
  const { title, icon, description, url } = props.data;

  return (
    <div className='usp-card'>
      <div className='usp-card-header'>
        <div
          className='icon-container'
          style={{ background: getColors(props.index) }}
        >
          <img src={icon} alt={title} />
        </div>
      </div>

      <h4 className='usp-card-title'>{title}</h4>
      <p className='usp-card-subtitle'>{description}</p>

      <Link
        to={url}
        className='insights-btn'
        style={{
          borderColor: getColors(props.index),
          color: getColors(props.index),
        }}
      >
        Read More
      </Link>
    </div>
  );
};

export default UspCard;
