import React from 'react';

import './styles.scss';

const TabTitle = ({
  title,
  className,
  setSelectedTab,
  activeClassName,
  icon,
  style,
  activeSelectedTab,
  disabled,
}) => {
  const onClick = () => {
    setSelectedTab();
  };

  return (
    <button
      className={`tab-btn ${className} ${
        activeSelectedTab
          ? activeClassName
            ? activeClassName
            : 'tab-btn-active'
          : ''
      }`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <img src={icon} alt='' className='tab-icon' />}
      <div className='tab-title'>{title}</div>
    </button>
  );
};

const Tab = ({ children }) => {
  return <div className='tabs-body'>{children}</div>;
};

const Tabs = (props) => {
  const { children, className, selectedTab } = props;

  return (
    <div className='tabs'>
      <div className={`tabs-list ${className}`}>
        {children &&
          children.length !== 0 &&
          children.map((item, index) => (
            <TabTitle
              key={index}
              index={index}
              icon={item.props.icon}
              title={item.props.title}
              style={item.props.style}
              disabled={item.props.disabled}
              className={item.props.className}
              selectedTab={item.props.selectedTab}
              activeClassName={item.props.activeClassName}
              activeSelectedTab={item.props.activeSelectedTab}
              setSelectedTab={() => item.props.setSelectedTab(item.props.title)}
            />
          ))}
      </div>
      {children[selectedTab]}
    </div>
  );
};

export { Tabs, Tab };
