import React from 'react';

import './styles.scss';

const MobileItem = (props) => {
  const {
    data: { title, description, logo, otherImage },
  } = props;

  return (
    <div className={`mobile-item ${otherImage !== '' ? 'noRightPadding' : ''}`}>
      <div className='mobile-item-left'>
        <h3 className='mobile-item-title'>{title}</h3>
        <p className='mobile-item-subtitle'>{description}</p>
      </div>
      <div className='mobile-item-right'>
        {otherImage === '' ? (
          <div className='img-container'>
            <div className='img-wrap'>
              <img src={logo} alt={title} />
            </div>
          </div>
        ) : (
          <div className='two-img-container'>
            <img src={logo} alt={title} className='first-img' />
            <img src={otherImage} alt='' className='second-img' />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileItem;
