import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { routes } from './routes/routes';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollHandler from './hocs/ScrollHandler';

import './styles/globals.scss';

const App = (props) => {
  return (
    <BrowserRouter>
      <ScrollHandler>
        <Navbar />

        <Switch>
          {routes.map((route, index) => {
            return <Route key={index} exact {...route} />;
          })}
        </Switch>

        <Footer />
      </ScrollHandler>
    </BrowserRouter>
  );
};

export default App;
