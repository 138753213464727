import React, { Fragment } from 'react';
import LockedIcon from '../../assets/images/locked.svg';
import UnlockedIcon from '../../assets/images/unlocked.svg';
import './styles.scss';

const TopicCard = ({
  topic,
  subtopics,
  setClickedItem,
  setIsModalVisible,
  onClick,
}) => {
  return (
    <div className='topic-card'>
      <h4 className='topic-card-title'>{topic}</h4>
      {subtopics.map((i, index) => {
        return index < 6 ? (
          <div
            key={index}
            className='subtopic'
            onClick={() => onClick(i._id)}
            style={i._id && { cursor: 'pointer' }}
          >
            <div className='name'>
              {index + 1}. {i.title}
            </div>
            <div name='icon'>
              {!i._id ? (
                <img src={LockedIcon} alt='locked' />
              ) : (
                <img src={UnlockedIcon} alt='un-locked' />
              )}
            </div>
          </div>
        ) : (
          <Fragment key={index} />
        );
      })}

      {subtopics.length >= 6 && (
        <p
          className='see-more'
          onClick={() => {
            setClickedItem({ topic, subtopics });
            setIsModalVisible(true);
          }}
        >
          See more
        </p>
      )}
    </div>
  );
};

export default TopicCard;
