import React from 'react';
import InsightItem from '../../components/InsightItem';
import DontMemorise from '../../assets/images/partners/dont-memorise.png';
import ASUImage from '../../assets/images/partners/asu.png';
import EduMediaImage from '../../assets/images/partners/edu-media.png';
import GeogebraImage from '../../assets/images/partners/geogebra.png';
import ResourceHeroImage from '../../assets/images/partners/resource-partners-hero.png';
import PetaIcon from '../../assets/images/partners/peta.png';
import ClimateKidsIcon from '../../assets/images/partners/climate-kids.png';
import PhetIcon from '../../assets/images/partners/phet.png';
import StoryNoryIcon from '../../assets/images/partners/story-nory.png';
import MathsFunIcon from '../../assets/images/partners/maths-fun.png';
import PuzzleIcon from '../../assets/images/partners/puzzle.png';
import './styles.scss';

const DATA = [
  {
    title: 'EduMedia Sciences',
    url: 'https://www.edumedia-sciences.com/',
    description:
      'Interactive simulations, videos and quizz in physics, chemistry, biology, earth science and maths',
    logo: EduMediaImage,
    activity:
      'https://www.edumedia-sciences.com/en/media/891-interactive-earth-climates',
  },
  {
    title: 'Don’t Memorise',
    url: 'https://dontmemorise.com/',
    description:
    'Award-winning simple bitesize video lessons for science and maths',
    logo: DontMemorise,
    activity:
    'https://dontmemorise.com/courses/introduction-to-friction/lessons/getting-introduced-friction/topic/what-is-friction/',
  },
  {
    title: 'ASU – AskaBiologist',
    url: 'https://askabiologist.asu.edu/',
    description:
    'Interactive simulations, videos and quizz in physics, chemistry, biology, earth science and maths',
    logo: ASUImage,
    activity: 'https://askabiologist.asu.edu/cell-viewer-game/play.html',
  },
  {
    title: 'GeoGebra',
    url: 'https://geogebra.org/',
    description:
    'World class Maths tool for graphing, 3D, geometry, algebra simulations and more…',
    logo: GeogebraImage,
    activity: 'https://www.geogebra.org/m/tn7wppdp#material/U972JMNR',
  },
];

const RESOURCE_PARTNERS = [
  {
    img: ClimateKidsIcon,
  },
  {
    img: PetaIcon,
  },
  {
    img: StoryNoryIcon,
  },
  {
    img: PhetIcon,
  },
  {
    img: MathsFunIcon,
  },
  {
    img: PuzzleIcon,
  },
];

const Partners = () => {
  return (
    <div className='partners'>
      <section className='hero-section'>
        <div className='hero-content'>
          <h1 className='hero-title hero-title--pink'>Resource Partners</h1>
          <p className='hero-description'>
            While a large part of Skipin resources is made by inhouse creators,
            it has crossed the borders of country and curriculum to partner with
            experts who create high quality educational resources for children.
            Interesting videos, simulations, puzzles and projects make Skipin a
            varied collection of truly world class resources.
          </p>
        </div>
        <div className='hero-img'>
          <img src={ResourceHeroImage} alt='' />
        </div>
      </section>

      <section className='partners-container'>
        {DATA.map((item, index) => (
          <InsightItem data={item} showUrl index={index} />
        ))}
      </section>

      <section className='resource-partners'>
        <h3 className='resource-partners-title'>Some More Resource Partners</h3>
        <div className='resource-partners-grid'>
          {RESOURCE_PARTNERS.map((partner) => {
            return (
              <div className='img-container'>
                <img src={partner.img} alt='' />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default Partners;
