import React, { useEffect, useState } from 'react';

import StarIcon from '../../assets/images/star.png';

import './styles.scss';

const TestimonialCard = (props) => {
  const [currentStars, setCurrentStars] = useState([]);
  const { name, designation, company, image, content, stars } = props.data;

  useEffect(() => {
    if (props.stars !== 0) {
      let updatedStars = [];

      for (let index = 0; index < stars; index++) {
        updatedStars.push(<img src={StarIcon} alt='Star' />);
      }

      setCurrentStars(updatedStars);
    }

    // eslint-disable-next-line
  }, [props.stars]);

  return (
    <div className='testimonial-card'>
      <div className='image-info'>
        <div className='image'>
          <img src={image} alt='name' />
        </div>

        <div className='info'>
          <p className='name'>{name}</p>

          {company ? (
            <p className='designation'>
              {designation}, {company}
            </p>
          ) : (
            <p className='designation'>{designation}</p>
          )}
        </div>
      </div>

      <div className='stars'>{currentStars}</div>

      <p className='content'>{content}</p>
    </div>
  );
};

export default TestimonialCard;
