import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import { routeConstants } from '../../common/routeConstants';

import './styles.scss';

const Drawer = ({ children, isDrawerVisible, closeDrawer }) => {
  useEffect(() => {
    document.addEventListener('keydown', closeDrawerOnEsc);

    return () => document.addEventListener('keydown', closeDrawerOnEsc);

    // eslint-disable-next-line
  }, []);

  const closeDrawerOnEsc = (e) => {
    if (e.keyCode === 27) {
      closeDrawer();
    }
  };

  return (
    isDrawerVisible && (
      <div className='drawer'>
        <div className='drawer-backdrop' onClick={closeDrawer} />
        <div className='drawer-body'>
          <div className='drawer-content'>
            <Link to='/learning'>Learning</Link>
            <Link to='/analytics'>Analytics</Link>
            <Link to='/learn-more'>Learn More</Link>
            <Link to={routeConstants.DOWNLOADAPP} className='join-now-btn'>
              Join Now
            </Link>
          </div>
        </div>
      </div>
    )
  );
};

export default Drawer;
