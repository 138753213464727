import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import { SUBJECT_CLASS_PLACEHOLDER } from '../../common/constants';
import { routeConstants } from '../../common/routeConstants';
import { substituteSubjectClass } from '../../utilities/helpers';
import Drawer from '../Drawer';

import './styles.scss';

const Navbar = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };
  return (
    <>
      <nav className='navbar'>
        <Link to='/'>
          <img src={Logo} alt='Skipin' className='navbar-logo' />
        </Link>

        <div className='navbar-links'>
          <Link
            to={routeConstants.CLASS_SUBJECTS.replace(
              ':subjectClassPath',
              substituteSubjectClass(SUBJECT_CLASS_PLACEHOLDER, 5, 'science')
            )}
          >
            Resources
          </Link>

          <Link to={routeConstants.ANALYTICS}>Analytics</Link>

          <Link to={routeConstants.DOWNLOADAPP}>App Skipin</Link>

          <Link to='#' onClick={() => window.open(process.env.REACT_APP_SKIPIN_APP_URL, '_blank')} className='join-now-btn' >
            Login
          </Link>
        </div>

        <button className='hamburger' onClick={() => setIsDrawerVisible(true)}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </nav>

      <Drawer closeDrawer={closeDrawer} isDrawerVisible={isDrawerVisible} />
    </>
  );
};

export default Navbar;
