import React from 'react';
import SkillsHeroImage from '../../assets/images/skills/skills-hero.png';
import SkillsFirst from '../../assets/images/skills/skills-first.png';
import SkillsSecond from '../../assets/images/skills/skills-second.png';
import './styles.scss';

const Skills = () => {
  return (
    <div className='partners'>
      <section className='hero-section'>
        <div className='hero-content'>
          <h1 className='hero-title hero-title--green'>21st Century Skills</h1>
          <p className='hero-description'>
            Skipin’s learning programme goes beyond the syllabus and integrates
            21st Century Skills into learning resources. The integration of
            Skipin’s approach to learning and 21st Century Skills is essential
            to prepare students for their future careers. In an age where
            standardised tests are the norm, integrating 21st Century skills in
            the curriculum go a long way in kindling curiosity and making them
            lifelong learners.
          </p>
        </div>
        <div className='hero-img'>
          <img src={SkillsHeroImage} alt='' />
        </div>
      </section>

      <section className='partners-container'>
        <div className='skills-item skills-item--green'>
          <img src={SkillsFirst} alt='' className='first-img' />
        </div>
        <div className='skills-item skills-item--blue skills-item-second'>
          <div className='img'>
            <img src={SkillsSecond} alt='' />
          </div>
          <div className='content'>
            <p>
              Skipin resources avoids teaching to the test and ensures that
              students learn to use critical thinking, creativity,
              collaboration, communication and problem solving in all the
              resources that they are completing. Every resource of Skipin has
              strong personal, societal or scientific context – and carefully
              integrates valuable life skills into curriculum. Skipin’s thinking
              curriculum provides students with authentic learning that prepares
              them for real-life problems that they will face as citizens of the
              world.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Skills;
