import React, { useState, useEffect } from 'react';

import OwlCarousel from 'react-owl-carousel';

// import Modal from '../../components/Modal';
import UspCard from '../../components/UspCard';
import apiService from '../../services/apiService';
import ClassSubjectCard from '../../components/ClassSubjectCard';
import TestimonialCard from '../../components/TestimonialCard';

import { userTestimonials, featuresInformation } from './dataManager';

import PlayIcon from '../../assets/images/play-icon.svg';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './styles.scss';

const Home = () => {
  const [classes, setClasses] = useState([]);
  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const closeModal = () => {
  //   setIsModalVisible(false);
  // };

  useEffect(() => {
    getClassesData();
  }, []);

  const getClassesData = () => {
    apiService
      .getClasses()
      .then((responseData) => {
        if (responseData && responseData.data) {
          setClasses(responseData.data);
        }
      })
      .catch((errorData) => {
        console.log('Unable to fetch classes data. Error: ', errorData);
      });
  };

  return (
    <div className='home'>
      {/* Hero Section */}
      <section className='hero'>
        <div className='hero-content'>
          <h1 className='hero-title'>
            Adaptive <br /> Self-learning
          </h1>

          <p className='hero-subtitle hero-subtitle--bold'>
            No two children learn the same way, so why make all of them take the
            same learning path?
          </p>
          <p className='hero-subtitle'>
            Unique pathway curated for your child according to her learning
            level, gaps and interests through AI based Adaptive Modelling. 1200+
            curated worksheets, videos, simulations, puzzles and projects - all
            mapped to integrate societal and scientific contexts, 21st Century
            Skills and certainly, the curriculum.
          </p>
          <p className='hero-subtitle'>
            Skipin - India’s first Adaptive Self-learning programme. Join now
            for an immersive learning experience.
          </p>

          <button
            className='watch-video-btn'
            // onClick={() => setIsModalVisible(true)}
          >
            Watch Video
            <img src={PlayIcon} alt='' />
          </button>
        </div>
      </section>

      {/* Classes */}
      {classes && classes.length !== 0 && (
        <section className='classes'>
          <div className='three-col-grid classes-grid'>
            {classes.map((classItem, classIndex) => {
              return <ClassSubjectCard key={classIndex} class={classItem} />;
            })}
          </div>
        </section>
      )}

      {/* What we Offer */}
      <section className='what-we-offer'>
        <h2 className='title'>Explore benefits of Skipin</h2>
        <div className='two-col-grid usp-container'>
          {featuresInformation.map((usp, index) => (
            <UspCard data={usp} index={index} />
          ))}
        </div>
      </section>

      {/* Testimonials */}
      <section className='testimonials'>
        <h2 className='title'>What people say</h2>

        <div className='testimonials-area'>
          <OwlCarousel
            nav
            loop={true}
            margin={24}
            dots={false}
            autoplay={true}
            autoplaySpeed={1500}
            autoplayTimeout={5000}
            navClass='carousel-nav'
            autoplayHoverPause={true}
            className='testimonials-container'
            responsive={{
              0: {
                items: 1,
              },
              768: {
                items: 2,
              },
              1280: {
                items: 3,
              },
            }}
          >
            {userTestimonials.map((item, index) => {
              return (
                <div className='item' key={index + 1}>
                  <TestimonialCard data={item} />
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </section>

      {/* <Modal closeModal={closeModal} isModalVisible={isModalVisible}>
        <video autoPlay controls width='100%'>
          <source src='https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4' />
        </video>
      </Modal> */}
    </div>
  );
};

export default Home;
