import React from 'react';

import { Link, useHistory } from 'react-router-dom';
import { routeConstants } from '../../common/routeConstants';

import FooterLogo from '../../assets/images/footer-logo.svg';

import './styles.scss';

const Footer = () => {
  const history = useHistory();
  return (
    <footer>
      <div className='footer-main'>
        <div className='membership-container'>
          <div className='membership-content'>
            <h2 className='membership-title'>Skipin Membership</h2>
            <p className='membership-subtitle'>
              Real time usage tracking updates you on how your child is enjoying
              Skipin. You also receive periodic detailed Competency Analytics
              Reports to help you identify the gifts and gaps. These insights
              help you participate in your child’s learning.
            </p>

            <button
              className='membership-join-btn'
              onClick={() => history.push(routeConstants.DOWNLOADAPP)}
            >
              App Skipin
            </button>
          </div>
        </div>

        <div className='footer'>
          <div className='info'>
            <img src={FooterLogo} alt='Skipin' className='logo-img' />

            <div className='info-description'>
              K-12 focussed EdTech initiative enabling ALL students to leverage
              technology to access quality education.
            </div>
          </div>

          <div className='links'>
            <div className='links-left'>
              <p className='link-title'>What we Offer?</p>

              <div className='links-content'>
                <Link to={routeConstants.SKILLS}>
                  21<sup>st</sup> Century Skills
                </Link>

                <Link to={routeConstants.ANALYTICS}>Analytics</Link>
                <Link to={routeConstants.PARTNERS}>Resource Partners</Link>
                <Link to={routeConstants.MOBILE}>Synchronised Mobile App</Link>
              </div>
            </div>

            {/* contact */}
            <div className='contact'>
              <p className='link-title'>Contact Us</p>

              <div className='links-content'>
                <a href='mailto:reachus@wiseowllearning.io'>
                  reachus@wiseowllearning.io
                </a>

                <a href='tel:+91 88977 11600'>+91 88977 11600</a>

                <a href='tel:+91 40 6719 4780'>+91 40 6719 4780</a>

                <p className='address'>
                  5B1000, Lodha Bellezza, KPHB Phase-4, Hyderabad, PIN - 500072,
                  Telangana, India
                </p>
              </div>
            </div>
          </div>
        </div>

        <p className='copy-text'>
          &copy; Copyright 2021 - Wise Owl Learning Private Limited
        </p>
      </div>
    </footer>
  );
};

export default Footer;
