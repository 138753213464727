import React, { useLayoutEffect } from 'react';

import { withRouter } from 'react-router';

const ScrollHandler = ({ history, children }) => {
  useLayoutEffect(() => {
    const routeChangeListenerDisengage = history.listen(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 150)
    });

    return () => {
      routeChangeListenerDisengage();
    };

    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};

export default withRouter(ScrollHandler);
