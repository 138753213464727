import React, { useState } from 'react';
import InsightItem from '../../components/InsightItem';
import AnalyticsHeroImage from '../../assets/images/analytics/analytics-hero.png';
import AnalyticsImage from '../../assets/images/analytics/analytics.jpeg';
import ProgressTrackImage from '../../assets/images/analytics/progress-track.jpeg';
import RealTimeImage from '../../assets/images/analytics/real-time.png';
import CompleteAnalysisImage from '../../assets/images/analytics/complete-analysis.png';
import './styles.scss';
import Modal from '../../components/Modal';

const DATA = [
  {
    id: 1,
    title: 'Real time insights – Progress Track',
    url: ProgressTrackImage,
    description: `Skipin shows real time completion of resources so that you are 
    always in the loop. You know exactly how many resources are completed – any time.
     Worksheet completion and uploaded projects can be viewed in real time on parent’s space.`,
    logo: RealTimeImage,
  },
  {
    id: 2,
    title: 'Competency Analytics at your fingertips',
    url: AnalyticsImage,
    description: `Discover your child’s gifts and gaps in a whole new way! Skipin
    transforms your child’s practice data into insightful Competency Analytics.
    Download Analytics Report for your child at any time and see where she stands in
    terms of 3 core competencies in each of the subjects – English, Science and Maths.
    `,
    logo: CompleteAnalysisImage,
  },
];

const Analytics = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [clickedItem, setClickedItem] = useState({});

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className='partners'>
        <section className='hero-section'>
          <div className='hero-content'>
            <h1 className='hero-title hero-title--purple'>Analytics</h1>
            <p className='hero-description'>
              Real time usage tracking updates you on how your child is enjoying
              Skipin. You also receive periodic detailed Competency Analytics
              Reports to help you identify the gifts and gaps. These insights
              help you participate in your child’s learning.
            </p>
          </div>
          <div className='hero-img'>
            <img src={AnalyticsHeroImage} alt='' />
          </div>
        </section>

        <section className='partners-container'>
          {DATA.map((item, index) => (
            <InsightItem
              data={item}
              index={index}
              showButton
              setIsModalVisible={setIsModalVisible}
              setClickedItem={setClickedItem}
              analyticsImage
            />
          ))}
        </section>
      </div>
      <Modal closeModal={closeModal} isModalVisible={isModalVisible}>
        {/* <iframe
          src={clickedItem.url}
          title={clickedItem.title}
          className='pdf-iframe'
        /> */}
        <img className='pdf-iframe' src={clickedItem.url} alt={clickedItem.title} />
      </Modal>
    </>
  );
};

export default Analytics;
