import React, { useEffect } from 'react';

import './styles.scss';

const Modal = ({ children, isModalVisible, closeModal }) => {
  useEffect(() => {
    document.addEventListener('keydown', closeModalOnEsc);

    return () => document.addEventListener('keydown', closeModalOnEsc);

    // eslint-disable-next-line
  }, []);

  const closeModalOnEsc = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  return (
    isModalVisible && (
      <div className='custom-modal'>
        <div className='custom-modal-backdrop' onClick={closeModal} />
        <div className='custom-modal-body'>{children}</div>
      </div>
    )
  );
};

export default Modal;
