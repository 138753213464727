import { routeConstants } from '../common/routeConstants';

import Home from '../containers/Home';
import Partners from '../containers/Insights/partners';
import Analytics from '../containers/Insights/analytics';
import Skills from '../containers/Insights/skills';
import Mobile from '../containers/Insights/mobile';
import Subjects from '../containers/Subjects';
import DownloadApp from '../containers/DownloadApp';

export const routes = [
  {
    path: routeConstants.HOME,
    component: Home,
  },
  {
    path: routeConstants.PARTNERS,
    component: Partners,
  },
  {
    path: routeConstants.ANALYTICS,
    component: Analytics,
  },
  {
    path: routeConstants.SKILLS,
    component: Skills,
  },
  {
    path: routeConstants.MOBILE,
    component: Mobile,
  },
  {
    path: routeConstants.CLASS_SUBJECTS,
    component: Subjects,
  },
  {
    path: routeConstants.DOWNLOADAPP,
    component: DownloadApp,
  },
];
