export const getColors = (index) => {
  switch (index % 4) {
    case 0:
      return '#66BA69';
    case 1:
      return '#8166BA';
    case 2:
      return '#E833AA';
    case 3:
      return '#0694F2';
    default:
      return '#66BA69';
  }
};

export const getLightColors = (index) => {
  switch (index % 4) {
    case 0:
      return '#f1fff2';
    case 1:
      return '#EBE1FF';
    case 2:
      return '#FFEEF9';
    case 3:
      return '#e1f3ff';
    default:
      return '#f1fff2';
  }
};
