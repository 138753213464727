import { apiConstants } from '../common/apiConstants';
import { baseAxiosInstance } from '.';

const apiService = {
  getClasses: () => baseAxiosInstance.get(apiConstants.GET_CLASSES),
  getLessonDetails: (className, subject) =>
    baseAxiosInstance.get(apiConstants.GET_SUBJECT_DETAILS(className, subject)),
  downloadApp: (dataPayload) => baseAxiosInstance.post(apiConstants.DOWNLOAD_APP, dataPayload),
};

export default apiService;
