export const routeConstants = {
  HOME: '/',
  ANALYTICS: '/analytics',
  PARTNERS: '/resource-partners',
  SKILLS: '/21st-century-skills',
  MOBILE: '/synchronised-mobile-app',
  CLASS_SUBJECTS: '/:subjectClassPath',
  SKIPIN_APP_BEST_WORKSHEET: (worksheetID) =>
    `${process.env.REACT_APP_SKIPIN_APP_URL}/freebie/${worksheetID}`,
  DOWNLOADAPP: '/download/app'
};
