import React from 'react';
import { getColors, getLightColors } from '../../utilities/getColor';
import './styles.scss';

const InsightItem = (props) => {
  const {
    index,
    showUrl,
    showButton,
    data: { title, url, description, logo, activity },
    setClickedItem,
    setIsModalVisible,
  } = props;
  return (
    <div
      className='usp-item'
      style={{ backgroundColor: getLightColors(index) }}
    >
      <div className='usp-item-left'>
        <h3 className='usp-item-title' style={{ color: getColors(index) }}>
          {title}
        </h3>
        {showUrl && (
          <a href={url} className='url'>
            {url}
          </a>
        )}
        <p className='usp-item-subtitle'>{description}</p>
        {showUrl && (
          <div className='row-center'>
            <a
              href={url}
              className='url-cta'
              style={{ color: getColors(index) }}
            >
              Visit Website
            </a>
            <span className='pipe'>|</span>
            <a
              href={activity}
              className='url-cta'
              style={{ color: getColors(index) }}
            >
              View Sample Activity
            </a>
          </div>
        )}
        {showButton && (
          <button
            className='insight-btn'
            style={{ color: getColors(index) }}
            onClick={() => {
              setClickedItem({ url: url, title: title });
              setIsModalVisible(true);
            }}
          >
            See Sample Reports
          </button>
        )}
      </div>
      <div className='usp-item-right'>
        <img
          src={logo}
          alt={title}
          className={props.analyticsImage ? 'analytics-img' : ''}
        />
      </div>
    </div>
  );
};

export default InsightItem;
