import React from 'react';

import { Link } from 'react-router-dom';
import { routeConstants } from '../../common/routeConstants';
import { substituteSubjectClass } from '../../utilities/helpers';
import { SUBJECT_CLASS_PLACEHOLDER } from '../../common/constants';

import './styles.scss';

const ClassSubjectCard = (props) => {
  return props.class ? (
    <div className='grade-card'>
      <div className='top-info'>
        <img src={props.class.icon} alt='Class' className='grade-card-img' />

        <h2 className='title'>Class {props.class.class}</h2>

        <p className='subtitle'>{props.class.description}</p>
      </div>

      {props.class.subjects && props.class.subjects.length !== 0 && (
        <div className='subject-lessons'>
          {props.class.subjects.map((subjectItem, subjectIndex) => {
            return (
              <div key={subjectIndex} className='subject-lessons-container'>
                <div
                  className='subject-pill'
                  style={{ backgroundColor: subjectItem.bgColor }}
                >
                  <img
                    src={subjectItem.icon}
                    className='subject-pill-img'
                    alt='Icon'
                  />

                  <span className='subject-pill-name'>
                    {subjectItem.subject}
                  </span>
                </div>

                <Link
                  to={
                    props.class.active
                      ? routeConstants.CLASS_SUBJECTS.replace(
                          ':subjectClassPath',
                          substituteSubjectClass(
                            SUBJECT_CLASS_PLACEHOLDER,
                            props.class.class,
                            subjectItem.subject
                          )
                        )
                      : '#'
                  }
                  className='lessons-link'
                >
                  {/* total number of resources */}
                  {subjectItem.lessonsCount || 0} Lessons{' '}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default ClassSubjectCard;
