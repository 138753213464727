import React from "react";

import { FormControl } from "react-bootstrap";

import Swal from 'sweetalert2';
import apiService from "../../services/apiService";
import AppStoreImage from '../../assets/images/app-store.png';
import GooglePlayImage from '../../assets/images/google-play.png';

import "./styles.scss";

const DownloadApp = () => {
  const [mobileNumber, setMobileNumber] = React.useState("");

  const getAppDownloadLink = () => {
    apiService
      .downloadApp({ mobileNumber })
      .then((responseData) => {
        if (responseData && responseData.data) {
          Swal.fire('Success!', 'Download link has been sent to your mobile number.', 'success');
        }
      })
      .catch((errorData) => {
        console.log(errorData);
      });
  };

  return (
    <div className="download">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="download-left">
            <a href="https://play.google.com/store/apps/details?id=io.wiseowllearning.skipin" target="_blank" rel="noreferrer noopener">
                <img className="download-left-icon" alt="Play Store" src={GooglePlayImage} />
            </a>
            <a href="https://apps.apple.com/us/app/skipin/id1537130813" target="_blank" rel="noreferrer noopener">
                <img className="download-left-icon" alt="App Store" src={AppStoreImage} />
            </a>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="download-right">
            <FormControl
              type="text"
              maxLength={10}
              value={mobileNumber}
              placeholder="Mobile Number"
              onChange={(e) => setMobileNumber(e.currentTarget.value)}
              className="download-right-input"
            />
            <button
              type="button"
              onClick={getAppDownloadLink}
              disabled={mobileNumber.length !== 10}
              className={`download-right-button ${
                mobileNumber.length !== 10
                  ? "download-right-button--disabled"
                  : ""
              }`}
            >
              Get Download Link
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
