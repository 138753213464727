import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '../../components/Tabs';
import TopicCard from '../../components/TopicCard';
import { routeConstants } from '../../common/routeConstants';
import { substituteSubjectClass } from '../../utilities/helpers';
import { SUBJECT_CLASS_PLACEHOLDER } from '../../common/constants';

import get from 'lodash/get';
import startCase from 'lodash/startCase';
import Modal from '../../components/Modal';
import apiService from '../../services/apiService';
import LockedIcon from '../../assets/images/locked.svg';
import UnlockedIcon from '../../assets/images/unlocked.svg';

import './styles.scss';

const Subjects = (props) => {
  const [classes, setClasses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [clickedItem, setClickedItem] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getClassesData();
  }, []);

  const getClassesData = () => {
    apiService
      .getClasses()
      .then((responseData) => {
        if (responseData && responseData.data) {
          setClasses(responseData.data);
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
      })
      .catch((errorData) => {
        console.log('Unable to fetch classes data. Error: ', errorData);
      });
  };

  useEffect(() => {
    if (props.match.params.subjectClassPath) {
      const paths = props.match.params.subjectClassPath.split('-');
      setSelectedClass(paths[4] || '');
      setSelectedSubject(paths[0] || '');
    }
  }, [props.match.params.subjectClassPath]);

  useEffect(() => {
    if (classes && classes.length) {
      const classData = classes.find(
        (classItem) => classItem.class === selectedClass
      );
      setSubjects(get(classData, 'subjects', []));
    }

    // eslint-disable-next-line
  }, [classes]);

  useEffect(() => {
    if (selectedClass && selectedSubject) {
      getSubjectWorksheets();
    }

    // eslint-disable-next-line
  }, [selectedClass, selectedSubject]);

  const getSubjectWorksheets = () => {
    apiService
      .getLessonDetails(selectedClass, selectedSubject)
      .then((responseData) => {
        if (responseData && responseData.data) {
          setLessons(responseData.data);
        }
      })
      .catch((errorData) => {
        console.log('Unable to fetch worksheets data. Error: ', errorData);
      });
  };

  const selectSubjectHandler = (subject = '') => {
    props.history.push(
      routeConstants.CLASS_SUBJECTS.replace(
        ':subjectClassPath',
        substituteSubjectClass(
          SUBJECT_CLASS_PLACEHOLDER,
          selectedClass,
          subject.toLowerCase()
        )
      )
    );
  };

  const selectClassHandler = (className) => {
    props.history.push(
      routeConstants.CLASS_SUBJECTS.replace(
        ':subjectClassPath',
        substituteSubjectClass(
          SUBJECT_CLASS_PLACEHOLDER,
          className,
          selectedSubject
        )
      )
    );
  };

  const selectWorksheetHandler = (worksheetID) => {
    if (worksheetID) {
      window.location.href =
        routeConstants.SKIPIN_APP_BEST_WORKSHEET(worksheetID);
    }
  };

  const getColorClass = (index) => {
    switch (index % 6) {
      case 0:
        return 'purple';
      case 1:
        return 'blue';
      case 2:
        return 'red';
      case 3:
        return 'green';
      case 4:
        return 'yellow';
      case 5:
        return 'pink';
      default:
        return 'purple';
    }
  };

  return (
    <>
      <div className='subjects'>
        <Tabs className='section-padding subjects-list'>
          {subjects &&
            subjects.length !== 0 &&
            subjects.map((subjectData, subjectIndex) => {
              return (
                <Tab
                  key={subjectIndex}
                  icon={subjectData.icon}
                  title={subjectData.subject}
                  className='subjects-tab-btn'
                  selectedTab={selectedSubject}
                  setSelectedTab={selectSubjectHandler}
                  activeClassName='subjects-tab-btn-active'
                  activeSelectedTab={
                    selectedSubject.toLowerCase() ===
                    subjectData.subject?.toLowerCase()
                  }
                />
              );
            })}
        </Tabs>

        <div className='grades-tabs-container'>
          <Tabs className='section-padding grade-list'>
            {classes &&
              classes.length !== 0 &&
              classes.map((classItem, classIndex) => {
                return (
                  <Tab
                    key={classIndex}
                    title={classItem.class}
                    className={`grade-tab-btn grade-tab-btn--${getColorClass(
                      classIndex
                    )}`}
                    selectedTab={selectedClass}
                    disabled={!classItem.active}
                    setSelectedTab={selectClassHandler}
                    activeClassName={`grade-tab-btn-active active-${getColorClass(
                      classIndex
                    )}`}
                    activeSelectedTab={selectedClass === classItem.class}
                  />
                );
              })}
          </Tabs>

          {selectedClass && selectedSubject && (
            <section className='grade-container'>
              <div className='grade-body'>
                <div className='grade-info'>
                  <h2 className='grade-title'>
                    Class {selectedClass} - {startCase(selectedSubject)}
                  </h2>
                  <p className='grade-subtitle'>
                    These are the list of worksheets available on Skipin for
                    class V in X. You can try out one worksheet from each
                    lesson. For access to all the interesting worksheets,
                    videos, activities and projects{' '}
                    <a href='https://app.skipin.io/'>get Skipin now</a>.
                  </p>
                </div>

                <div className='three-col-grid topics-container'>
                  {lessons &&
                    lessons.length !== 0 &&
                    lessons.map((lessonItem, lessonIndex) => {
                      return (
                        <TopicCard
                          key={lessonIndex}
                          topic={lessonItem.chapter}
                          setClickedItem={setClickedItem}
                          onClick={selectWorksheetHandler}
                          subtopics={lessonItem.worksheets}
                          setIsModalVisible={setIsModalVisible}
                        />
                      );
                    })}
                </div>
              </div>
            </section>
          )}
        </div>
      </div>

      <Modal closeModal={closeModal} isModalVisible={isModalVisible}>
        <div className='subtopic-modal-content'>
          <h4 className='subtopic-title'>{clickedItem?.topic}</h4>
          <ul className='subtopics-all-list'>
            {clickedItem?.subtopics?.map((i, index) => {
              return (
                <li
                  key={index}
                  className='subtopic row subtopic-item'
                  onClick={() => selectWorksheetHandler(i._id)}
                  style={i._id && { cursor: 'pointer' }}
                >
                  <div className='name'>
                    {index + 1}. {'   '}
                    {i.title}
                  </div>
                  <div name='icon'>
                    {!i._id ? (
                      <img src={LockedIcon} alt='locked' />
                    ) : (
                      <img src={UnlockedIcon} alt='un-locked' />
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default Subjects;
