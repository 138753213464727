import { routeConstants } from '../../common/routeConstants';

import MobileIcon from '../../assets/images/usp/mobile.svg';
import Abhigyan from '../../assets/images/testimonials/abhigyan.jpg';
import PlayStore from '../../assets/images/testimonials/play-store.png';
import AppStore from '../../assets/images/testimonials/app-store.png';
import User from '../../assets/images/testimonials/play-store.png';
import Arpita from '../../assets/images/testimonials/arpita.jpg';
import Joe from '../../assets/images/testimonials/joe.jpg';
import Diksha from '../../assets/images/testimonials/diksha.jpg';
import Email from '../../assets/images/testimonials/email.png';
import Anamika from '../../assets/images/testimonials/anamika.jpg';
import Harsh from '../../assets/images/testimonials/harsh.jpg';
import CenturySkillsIcon from '../../assets/images/usp/21-century-skills.svg';
import ResourcePartnersIcon from '../../assets/images/usp/resource-partners.svg';

export const featuresInformation = [
  {
    icon: CenturySkillsIcon,
    title: '21st Century Skills',
    url: routeConstants.SKILLS,
    // eslint-disable-next-line max-len
    description:
      '“We are currently preparing students for jobs and technologies that don’t yet exist... in order to solve problems that we don’t even know are problems yet. ”Skipin is the first learning programme that goes beyond the syllabus and integrates 21st Century Skills into learning resources. ',
  },
  {
    icon: CenturySkillsIcon,
    title: 'Analytics',
    url: routeConstants.ANALYTICS,
    // eslint-disable-next-line max-len
    description:
      'Real time usage tracking updates you on how your child is enjoying Skipin. You also receive periodic detailed Competency Analytics Reports to help you identify the gifts and gaps. These insights help you participate in your child’s learning.',
  },
  {
    icon: ResourcePartnersIcon,
    title: 'Resource Partners',
    url: routeConstants.PARTNERS,
    // eslint-disable-next-line max-len
    description:
      'While a large part of Skipin resources is made by inhouse creators, it has crossed the borders of country and curriculum to partner with experts who create high quality educational resources for children.Interesting videos, simulations, puzzles and projects make Skipin a varied collection of truly world class resources.',
  },
  {
    icon: MobileIcon,
    title: 'Synchronised Mobile App',
    url: routeConstants.MOBILE,
    // eslint-disable-next-line max-len
    description:
      'App Skipin on iOS and Android synchronises seamlessly with Skipin web platform. You always have Skipin with you and can learn on the go. On smartphone or on a tablet, Skipin app makes learning easy and fun.',
  },
];

export const userTestimonials = [
  {
    name: 'Abhigyan',
    designation: 'Student',
    company: 'Class 7',
    image: Abhigyan,
    stars: 5,
    content:
      'I love puzzles, projects and stories. I have a lot of fun when I am on Skipin. Is this really studying?',
  },
  {
    name: 'Yean Unnie',
    designation: 'User',
    company: 'Play Store Review',
    image: PlayStore,
    stars: 5,
    content:
      '5* Its a really helpful app … and its easy to use also … in my opinion you can add some senior classes too.',
  },
  {
    name: 'Anamika',
    designation: 'Mother & Teacher',
    company: '',
    image: Anamika,
    stars: 5,
    content:
      'I always wished l had enough time to connect to each of my students at an individual level. Skipin is doing exactly that.',
  },
  {
    name: 'Harsh Verdhan Singh',
    designation: 'Student',
    company: 'Class 8',
    image: Harsh,
    stars: 5,
    content:
      'I have downloaded the app and my experience was wonderful the all content was from my book only in a systematic manner. Overall the app is best for learning.',
  },
  {
    name: 'Vandana Singh',
    designation: 'User',
    company: 'By Email',
    image: Email,
    stars: 5,
    content:
      'Some of the amazing project tasks for primary grades. Uniqueness is that tasks are situated in an interesting context and introduced with a brief about the idea. It demands every child to express his/unique way of doing it.',
  },
  {
    name: 'Joe Arya',
    designation: 'Student',
    company: 'Presidency School Bangalore South',
    image: Joe,
    stars: 5,
    content:
      'Skipin is really wonderful to use with activity and projects. As we all have  online classes from school, Skipin helps to solve and do the activities practically which gives an in depth understanding of each concept.',
  },
  {
    name: 'Vasundhara',
    designation: 'Student',
    company: 'Class 5',
    image: User,
    stars: 5,
    content:
      'It is amazing. We just love it and it’s very easy and convenient to use. I tried out all the subjects. The activities, projects and walkthroughs are amazing. I love it.',
  },
  {
    name: 'Diksha',
    designation: 'Student',
    company: 'Class 8',
    image: Diksha,
    stars: 5,
    content:
      'Skipin is a very helpful app to learn and study all the concepts. I am enjoying solving quizzes on it.',
  },
  {
    name: 'SourceBook',
    designation: 'User',
    company: 'AppStore Review',
    image: AppStore,
    stars: 5,
    content:
      '5* Nice coverage of syllabus – app is designed well and cover the topic in an easy way.',
  },
  {
    name: 'Arpita',
    designation: 'Mother',
    company: '',
    image: Arpita,
    stars: 5,
    content:
      'I love the progressive activities! I don’t have time to search for worksheets for my 11 year old son. The activities in Skipin are designed to address the right learning at the right age.',
  },
];
