import React from 'react';
import MobileItem from '../../components/MobileItem';

// images
import MacbookSkipinWebImage from '../../assets/images/mobile/macbook-skipin-web.png';
import PracticeOnGoImage from '../../assets/images/mobile/practice-on-go.png';
import IphoneHeroImage from '../../assets/images/mobile/iphone-hero.png';
import IphoneCart from '../../assets/images/mobile/iphone-cart.png';
import IphoneUpload from '../../assets/images/mobile/iphone-upload.png';
import IphoneApp from '../../assets/images/mobile/iphone-app.png';
import MacbookImage from '../../assets/images/mobile/macbook.png';

import './styles.scss';

const DATA = [
  {
    title: 'Switch web-to-app',
    description:
      'Web and app are perfectly synchronised – the app takes you exactly where you have stopped working on the web platform. So, you are always updated and always ready on Skipin.',
    logo: IphoneApp,
    otherImage: MacbookSkipinWebImage,
  },
  {
    title: 'Practice on the go',
    description:
      'Worksheets are most fun on the app. You can sit on the couch or in a park bench – you can practice Skipin worksheets everywhere. Activities and projects are also available on the app.',
    logo: PracticeOnGoImage,
    otherImage: '',
  },
  {
    title: 'Upload and Download Projects',
    description:
      'Take a photo on your phone and upload your completed project with one simple click on the app. You can also download your projects and save in your phone and on the cloud.',
    logo: IphoneUpload,
    otherImage: '',
  },
  {
    title: 'Load your cart',
    description:
      'Check out Skipin products on the go. Load your cart and buy in the app - visiting the website is not required for purchase. You can also buy Skipin packages (subjects) directly from the app!',
    logo: IphoneCart,
    otherImage: '',
  },
];

const Mobile = () => {
  return (
    <div className='mobile'>
      <section className='hero-section'>
        <div className='hero-content'>
          <h1 className='hero-title hero-title--blue'>
            Synchronised Mobile App
          </h1>
          <p className='hero-description'>
            App Skipin on iOS and Android synchronises seamlessly with Skipin
            web platform. You always have Skipin with you and can learn on the
            go. On smartphone or on a tablet, Skipin app makes learning easy and
            fun.
          </p>
        </div>
        <div className='hero-img'>
          <img src={MacbookImage} alt='' />
          <img src={IphoneHeroImage} alt='' className='hero-second-img' />
        </div>
      </section>

      <section className='partners-container'>
        {DATA.map((item, index) => {
          return <MobileItem data={item} index={index} />;
        })}
      </section>
    </div>
  );
};

export default Mobile;
